/**
 * A module for what for the availability of a DOM element by selector.
 * Useful for single page apps where the DOM element we need to know about
 * may not be available at the time of initial code execution
 */
let listeners = [],
    doc = window.document,
    MutationObserver = window.MutationObserver || window.WebKitMutationObserver,
    observer;

/**
 * Listen for availablity of certain DOM elements
 *
 * @param {any} selector The selector of an element you need to know about
 * @param {any} fn The callback when it become available
 */
const elementReady = function(selector, fn) {
    // Store the selector and callback to be monitored
    listeners.push({
        selector: selector,
        fn: fn
    });

    if(!observer) {
        // Watch for changes in the document
        observer = new MutationObserver(check);
        observer.observe(doc.documentElement, {
            childList: true,
            subtree: true
        });
    }
    // Check if the element is currently in the DOM
    check();
};

/**
 * Check to see if a given eleemnt is in the DOM
 */
const check = function() {
    // Check the DOM for elements matching a stored selector
    for (var i = 0, len = listeners.length, listener, elements; i < len; i++) {
        listener = listeners[i];
        // Query for elements matching the specified selector
        elements = doc.querySelectorAll(listener.selector);
        for(var j = 0, jLen = elements.length, element; j < jLen; j++){
            element = elements[j];
            // Make sure the callback isn't invoked with the
            // same element more than once
            if(!element.ready){
                element.ready = true;
                // Invoke the callback with the element
                listener.fn.call(element, element);
            }
        }
    }
};

module.exports = elementReady;