const elementReady = require('./lib/element-ready');

const scripts = document.querySelectorAll("script[src]");
const lastScript = scripts[scripts.length - 1];
let baseHost = lastScript.src.match(/(http.?:\/\/[^\/]+)/)[1];

if (baseHost.match(/localhost/)) {
    baseHost = 'https://cdn.getparkave.com';
}

if (baseHost.match(/app.getparkave.com/)) {
    baseHost = 'https://cdn.getparkave.com';
}

if (window.parkave_config && window.parkave_config.asset_host) {
    baseHost = `https://${window.parkave_config.asset_host}`;
}

const baseUrl = 'https://cdn.getparkave.com/view/';  // `${baseHost}/view/`;

elementReady('ins.parkave-placement', function (el) {
    if (!el.dataset.rendered) {
        el.dataset.rendered = true;

        // Generally we want to use a timestamp rounded to the nearest 10 minutes
        // unless the timestamp is within the last 10 minutes, meaning the user
        // just edited it
        let timestamp;
        let mins = 5; // number of minutes to cache on cdn for
        let coeff = 1000 * 60 * mins;
        let nextTs = Math.ceil(new Date() / coeff) * coeff;
        if (el.dataset.parkaveTimestamp && ((nextTs - coeff) < el.dataset.parkaveTimestamp)) {
            timestamp = el.dataset.parkaveTimestamp;
        } else {
            timestamp = nextTs;
        }

        timestamp = `?ts=${ timestamp }`;

        if (el.dataset.parkaveSize == 'fixed' || el.dataset.parkaveSize == 'flexible') {
            let frame = document.createElement('iframe');

            frame.frameBorder = 0;
            frame.src = baseUrl + el.dataset.parkavePlacement + '.html' + timestamp;

            el.appendChild(frame);
            el.dataset.rendered = true;
            el.parentNode.replaceChild(frame, el);

            if (el.dataset.parkaveSize == 'fixed') {
                frame.style.maxWidth = '100%';
                frame.style.height = el.dataset.parkaveHeight + 'px';
                frame.style.width = el.dataset.parkaveWidth + 'px';
            }

            if (el.dataset.parkaveSize == 'flexible') {
                let ratio = el.dataset.parkaveHeight / el.dataset.parkaveWidth;
                frame.style.maxWidth = '100%';
                frame.style.width = '10000px';
                frame.style.height = frame.offsetWidth * ratio + 'px';
                window.addEventListener('resize', function () {
                    frame.style.height = frame.offsetWidth * ratio + 'px';
                })
            }

            if (el.dataset.parkaveInline == 'true' && window.innerWidth < 800) {
                frame.width = '300px';
                frame.style.width = '300px';
            }

            // tell the ad itself what size it's going to be - because it may be overridden
            let newConfig = {
                size: {
                    type: el.dataset.parkaveSize, width: el.dataset.parkaveWidth, height: el.dataset.parkaveHeight
                }
            }

            if (el.dataset.parkaveClickTracker && el.dataset.parkaveClickTracker[0] != '%') {
                newConfig['click_tracker'] = el.dataset.parkaveClickTracker;
            }

            frame.onload = function () {
                setTimeout(function() {
                    frame.contentWindow.postMessage({
                        type: 'bsa.ad.configure',
                        config: newConfig
                    }, '*')

                }, 100)
            }


        } else if (el.dataset.parkaveSize == 'out-of-page') {
            // out of page always executes as a JS file
            if (window.top !== window) {
                // need to break out of a frame
                window.top.postMessage({
                    type: 'parkave-out-of-page',
                    element: el.outerHTML
                }, '*');

            } else {
                var js = document.createElement('script');
                js.src = baseUrl + el.dataset.parkavePlacement + '.js' + timestamp;
                js.async = true;
                document.body.appendChild(js);
            }
        }
    }
});

window.addEventListener('message', function (e) {
    if (e.data.type == 'parkave-out-of-page') {
        window.parkaveConfig = window.parkaveConfig || {};
        var wrapper = document.createElement('div');
        wrapper.innerHTML = e.data.element;
        wrapper.firstChild.removeAttribute('data-rendered');
        document.body.appendChild(wrapper.firstChild);
    }
});